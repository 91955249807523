import React from "react";
import { Link } from "gatsby";
import { Row, Col } from "react-bootstrap"
import { path } from "../../helper"
import "./ContentfulTemplateTileAlt.scss"
import Button from "./ContentfulTemplateButton"
const ContentfulTemplateTileAlt = ({ urlSlug, pageType, pageImage, title, description }) => {
    var link = path(pageType, urlSlug)
    return (
        <div className={`tile-alt`}>
            <Link to={`/${link}`}>
                <img src={pageImage.file.url} alt={pageImage.title} />
            </Link>
            <div className={`tile-content`}>
                <h3>{title}</h3>
                <p>{description}</p>
                <Button title="Read more" targetContent={link} targetType="Internal Link" />
            </div>
            <div className="separator"></div>
        </div>
    )
}

export default ContentfulTemplateTileAlt