

import React, { Component } from "react"
import {graphql, useStaticQuery} from "gatsby"
import { Link } from "gatsby"
import "./Nav.scss"
import Logo from '../images/Vyanta_logo.svg'
import Nav from 'react-bootstrap/Nav'
import scrollTo from 'gatsby-plugin-smoothscroll';

const PhoneNumber = () => {
  const { site: { siteMetadata: { phone } } } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          phone {
            en_GB {
              phone_number
              title
            }
            en_US {
              phone_number
              title
            }
          }
        }
      }
    }
  `)
  return (
    <>
      <li><a href={`tel:${phone['en_GB'].phone_number}`}>{phone['en_GB'].title}</a></li>
      <li><a href={`tel:${phone['en_US'].phone_number}`}>{phone['en_US'].title}</a></li> 
    </>
  )
}

class SiteNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      subMenuSector: false,
      subMenuFeed: false,
    };
    this.toggleClass = this.toggleClass.bind(this);
  }
  toggleClass() {
    const currentState = this.state.active;
    this.setState({ active: !currentState });
  }
  expandMenuSector(val) {
     this.setState({ subMenuSector: !val });
  }
  expandMenuFeed(val) {
    this.setState({ subMenuFeed: !val });
 }
  render() {
    return (
      <div id="menu">
        <header id="header" className={this.state.active ? 'bg-yellow header-nav-open' : 'bg-yellow'}></header>
        <Nav className="justify-content-center" >
          <Nav.Item>
            <Link className="logo" to={`/`}><img src={Logo} alt={`vyanta_logo`} /></Link>
          </Nav.Item>
        </Nav>
        <div className="d-none d-lg-block d-xl-block">
          <div id="desktop-nav-left" className="desktop-nav">
            <ul id="menu-desktop-nav-left" className="menu">
              <li><Link to={`/collaboration`}>collaboration</Link></li>
              {/* <li><Link to={`/education`}>education</Link></li> */}
              <li class="nav-item dropdown">
                <a className="dropbtn dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  sector
                </a>
                <div className="dropdown-content dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <Link className="dropdown-item" to={`/business`}>business</Link>
                <Link className="dropdown-item" to={`/education`}>education</Link>
                </div>
              </li>
            </ul>
          </div>
          <div id="desktop-nav-right" className="desktop-nav">
            <ul id="menu-desktop-nav-right" className="menu">
             <li class="nav-item dropdown">
                <a className="dropbtn dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  feed
                </a>
                <div className="dropdown-content dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <Link className="dropdown-item" to={`/blog`}>blog</Link>
                <Link className="dropdown-item" to={`/case-studies`}>case studies</Link>
                </div>
              </li>
              <li onClick={() => scrollTo('#contactus')}><span href="/#contactus">contact us</span></li>
            </ul>
          </div>
        </div>
        <div className="d-block d-sm-none">
          <span id="btn-nav" onClick={this.toggleClass} className={this.state.active ? 'btn-nav-open nav-open' : 'btn-nav-close'}>
            <span className="relative">
              <span className="line line-01"></span>
              <span className="line line-02"></span>
              <span className="line line-03"></span>
            </span>
          </span>
          <div id="btn-nav-anim"></div>
          <div id="nav" className="nav-close" style={this.state.active ? { visibility: 'visible' } : { visibility: 'hidden' }}>
            <div className="table-cell">
              <ul id="menu-itgl-menu" className="menu">
                <li><Link onClick={this.toggleClass}  to={`/`}>home</Link></li>
                <li><Link onClick={this.toggleClass}  to={`/collaboration`}>collaboration</Link></li>
                <li><Link onClick={() => this.expandMenuSector(this.state.subMenu)} >sector<img className="dropdown_icon" src="https://images.ctfassets.net/edx0x8hcmdyc/7w3Eld5g98eNsRqw6VIuHf/a555da50b75ff775538d1e4ca53a6276/angle-down-light.svg" /></Link></li>
                {this.state.subMenuSector ? (
                    <>
                    <li><Link className="subMenu" onClick={this.toggleClass}  to={`/business`}>business</Link></li>
                    <li><Link className="subMenu" onClick={this.toggleClass}  to={`/education`}>education</Link></li>
                    </>
                ) : (
                    <></>
                )}
                <li><Link onClick={() => this.expandMenuFeed(this.state.subMenu2)} >feed<img className="dropdown_icon" src="https://images.ctfassets.net/edx0x8hcmdyc/7w3Eld5g98eNsRqw6VIuHf/a555da50b75ff775538d1e4ca53a6276/angle-down-light.svg" /></Link></li>
                {this.state.subMenuFeed ? (
                    <>
                    <li><Link className="subMenu" onClick={this.toggleClass}  to={`/blog`}>blog</Link></li>
                    <li><Link className="subMenu" onClick={this.toggleClass}  to={`/case-studies`}>case studies</Link></li>
                    </>
                ) : (
                    <></>
                )}
                <li onClick={() => scrollTo('#contactus')}><span onClick={this.toggleClass}  >contact us</span></li>
                <li className="separator" style={{margin: "auto"}}></li>
                <PhoneNumber />
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}



export default SiteNav

// import React from "react"
// import { Link } from "gatsby"
// import "./Nav.scss"
// import Logo from '../images/Vyanta_logo.svg'
// import Nav from 'react-bootstrap/Nav'


// const SiteNav = () => {
//   return (
//     <div>
//       <header id="header" class="bg-yellow header-nav-open"></header>
//       <Nav className="justify-content-center" >
//         <Nav.Item>
//           <Link className="logo" to={`/`}><img src={Logo} alt={`vyanta_logo`} /></Link>
//         </Nav.Item>
//       </Nav>
//       <div className="d-none d-lg-block d-xl-block">
//         <div id="desktop-nav-left" className="desktop-nav">
//           <ul id="menu-desktop-nav-left" class="menu">
//             <li><a href="/collaboration">collaboration</a></li>
//             <li><a href="/education">education</a></li>
//           </ul>
//         </div>
//         <div id="desktop-nav-right" className="desktop-nav">
//           <ul id="menu-desktop-nav-right" class="menu">
//             <li><a href="/blog">blog</a></li>
//             <li><a href="/#contactus">contact us</a></li>
//           </ul>
//         </div>
//       </div>
//       <div className="d-block d-sm-none">
//         <a href="#" id="btn-nav" className="btn-nav-close. btn-nav-open nav-open">
//           <span className="relative">
//             <span className="line line-01"></span>
//             <span className="line line-02"></span>
//             <span className="line line-03"></span>
//           </span>
//         </a>
//         <div id="btn-nav-anim"></div>
//         <div id="nav" className="nav-close" style={{visibility: 'visible'}}>
//           <div className="table-cell">
//             <ul id="menu-itgl-menu" className="menu">
//               <li><a href="/">Home</a></li>
//               <li><a href="/collaboration">Collaboration</a></li>
//               <li><a href="/education">Education</a></li>
//               <li><a href="/blog">Blog</a></li>
//               <li><a href="/#contactus">Contact Us</a></li>
//             </ul>
//           </div>
//         </div>
//       </div>
//     </div>

//   )
// }

// export default SiteNav