import React from "react";
import Table from 'react-bootstrap/Table'
import "./ContentfulTemplateTable.scss"
const ContentfulTemplateTable = ({ entryTitle, table: { tableData } }) => {
    return (
        <Table responsive="sm" striped bordered hover>
            <thead>
                <tr>
                    {tableData[0].map((data, i) => {
                        return (
                            <th key={i}>{data}</th>
                        )
                    })}
                </tr>
            </thead>
            <tbody>
                {tableData.map((data, i) => {
                    if (i !== 0) {
                        return (
                            <tr key={i}>
                                {data.map((subData, i) => {
                                    return (
                                        <td key={i}>{subData}</td>
                                    )
                                })}
                            </tr>
                        )
                    }
                    return <></>
                })}
            </tbody>
        </Table>
    )
}

export default ContentfulTemplateTable