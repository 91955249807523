import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import Logo from "../images/logo.png"
const Head = ({title, description}) => {
    // const { site: {siteMetadata: {title: metaTitle, description: metaDescription, author, description, email, title, logo, phone_number, address  }}} = useStaticQuery(graphql`
    const { site: {siteMetadata: {author, description: siteDescription, email, title: siteTitle, phone_number, address  }}} = useStaticQuery(graphql`
    query { site { siteMetadata { author description email title phone_number address } } } `)
    return (
        // <Helmet title={ title } titleTemplate={`${ metaTitle } - Page | %s`}>
        <Helmet title={ !!title ? title : siteTitle } htmlAttributes={{ lang : 'en' }} titleTemplate={`%s - ${ author }`}>
            <meta charset="utf-8"/>
            <meta name="description" content={ !!description ? description : siteDescription } />
            <meta name="author" content={ author } />
            <meta name="address" content={ address } />
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0"></meta>
            <meta property="og:image" content={ Logo }/> 
            <meta property="og:title" content={ `${!!title ? title : siteTitle} - ${author}` } />
            <meta name="og:email" content={email}/>
            <meta name="og:phone_number" content={phone_number}/>
            
        </Helmet>
    )
}

export default Head