import React, { Component } from 'react';
import './ContentfulTemplateVideo.scss';

class ContentfulTemplateVideo extends Component {
    constructor(props) {
        super(props);
    }

    getEmbeddedUrl(videoUrl) {
      if (videoUrl.includes("vimeo.com/")) {
          let videoId = videoUrl.split("/").pop(); // Get the last part of the URL

          // Handle specific case with hash
          if (videoId === "35c1f708e9") {
              videoId = videoUrl.split("/").slice(-2, -1)[0]; // Get the second-to-last part of the URL
          } else if (videoId.includes("?") || videoId.includes("#")) {
              videoId = videoId.split(/[?#]/)[0]; // Remove query params/fragments
          }

          return `https://player.vimeo.com/video/${videoId}`; // Convert to embed URL
      }
      return videoUrl; // Return as is for already embedded URLs
    }

    render() {
        const embeddedUrl = this.getEmbeddedUrl(this.props.videoUrl);
        return (
            <iframe
                width="100%"
                height="550"
                src={embeddedUrl}
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
            ></iframe>
        );
    }
}

export default ContentfulTemplateVideo;
