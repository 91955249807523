import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import "./Footer.scss"
import { graphql, useStaticQuery, Link } from "gatsby"
import Logo from '../images/logo.svg'
const Footer = () => {
    const { site: { siteMetadata: { email, phone_number, author, phone } } } = useStaticQuery(graphql`
    query { 
        site { 
            siteMetadata { 
                author description email title phone_number
                phone {
                    en_GB {
                        phone_number
                        title
                    }
                    en_US {
                        phone_number
                        title
                    }
                } 
            } 
        } 
    }
    `)
    return (
        <footer>
            <Container>
                <Row>
                    <Col md={12}>
                        <div><img src={Logo} alt={`vyanta_logo`} /></div>
                        <div><embed style={{ width: "90px", margin: "20px 0 " }} src="https://images.ctfassets.net/edx0x8hcmdyc/6I5k0l8XwQ9oGTl3YuZoc9/370569c9ff0c492f08337ff58122d4b1/vyanta-cisco-partner.svg"></embed></div>
                        <div className="footer-contact"><a href={`tel:${phone['en_GB'].phone_number}`}>{phone['en_GB'].title}</a> <a href={`tel:${phone['en_US'].phone_number}`}>{phone['en_US'].title}</a> <a className="footer-contact" href={`mailto:${email}`}>{email}</a></div>
                        <div className="separator"></div>
                        <div><span className="footer-terms" ><Link to={'/privacy-notice'}>Privacy Notice</Link></span></div>
                        <div><span>Copyright 2020 - 2025 {author}</span></div>
                    </Col>
                </Row>
            </Container>
        </footer>

    )
}

export default Footer