module.exports = {
    templateWidth(width) {
        let obj = {
            "Full Width": 12,
            "Half Width": 6,
            "Two-Thirds Width": 8,
            "One Third Width": 4,
            "One Fourth Width": 3,
        }

        return obj[width]
    },
    formatForId(id) {
        return id.replace(/[\s]/g, "").toLowerCase();
    },
    position(p, def) {
        return !!p ? p.toLowerCase() : def;
    },
    path(pageType, urlSlug){
        return pageType && pageType !== "Main" ? `${pageType.toLowerCase()}/${urlSlug}` : urlSlug
    }
}