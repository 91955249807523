import React from "react";
import { Row, Col } from "react-bootstrap"
import * as Components from './AllComponents'
import "./ContentfulTemplateHeader.scss"

const ContentfulTemplateHeader = ({ heading, subHeading, heroImage, buttons }) => {
    return (
        <Row className="header" style={{backgroundImage:`url(${heroImage.file.url})`}}>
            <Col md={12}>
                <div className="text-center text-white">
                    <h1>{heading}</h1>
                    <div className="separator"></div>
                    <p>{subHeading}</p>
                    <div>
                        <Buttons buttons={buttons}/>
                    </div>
                </div>
            </Col>
        </Row>
    )
}
const Buttons = ({buttons}) => {
    if(buttons) {
        return (
            <>
            {
                buttons.map((button, i) => {
                    let Component = Components[button.__typename];
                    return (<Component key={i} {...button}/>)
                })
            }
            </>
        )
    }
    return (<></>)
}

export default ContentfulTemplateHeader