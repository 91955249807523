import React from "react";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import * as Components from "./AllComponents"
import "./ContentfulTemplateBodyText.scss"
import LightboxImage from './LightboxImage';
const ContentfulTemplateBodyText = ({heading, subHeading, bodyText, textPosition, __typename}) => {
    return (
        <div className={`${__typename}`}>
            <Heading  heading={heading} />
            <SubHeading subHeading={subHeading} />
            <Separator  heading={heading} />
            <BodyText bodyText={bodyText}/>
        </div>
    )
}

function returnProps(props) {
    let obj = {}
    for (let prop in props) {
        obj[prop] = props[prop]['en-US']
    }
    return obj
}
const Separator = ({heading}) => { 
    if (heading) {
        return <div className="separator"></div>
    }
    return <></>
}

const Heading = ({heading}) => { 
    if (heading) {
        return  <h3>{heading}</h3>
    }
    return <></>
}

const SubHeading = ({subHeading}) => { 
    if (subHeading) {
        return  <h2>{subHeading}</h2>
    }
    return <></>
}

const BodyText = ({bodyText}) => {
    const options = {
        renderNode: {
            "embedded-asset-block": (node) => {
                let alt = node.data.target.fields.title ? node.data.target.fields.title['en-US'] : ''
                let url = node.data.target.fields.file['en-US'].url
                let contentType = node.data.target.fields.file['en-US'].contentType 
                if (contentType.includes("image")) {
                    if(node.data.target.fields.description) {
                        return  <a href={node.data.target.fields.description["en-US"]} target="_blank" ><img src={url} alt={alt} width={`100%`} className={`p-10 m-a`}/></a>
                    }
                    return  <LightboxImage imgSrc={url} imgAlt={alt} width={`100%`} className={`p-10 m-a`}/>
                    // return  <img src={url} alt={alt} width={`40%`} className={`p-10 m-a`}/>
                } 
                return <></>
            },
            "embedded-entry-block": ({data: {target}}) => {
                let contentType = target.sys.contentType.sys.id;
                contentType = "Contentful" + contentType.replace(contentType.charAt(0), contentType.charAt(0).toUpperCase());
                let Component = Components[contentType];
                let fields = returnProps(target.fields)
                return (<Component {...fields}/>)
            },
        }
    }
    if (bodyText) {
        return documentToReactComponents(bodyText.json, options)
    }
    return <></>
}

export default ContentfulTemplateBodyText
