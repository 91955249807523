import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import * as Yup from 'yup';
import { Formik, Form, Field } from "formik";
import "bootstrap/dist/css/bootstrap.css";
import "./Form.scss"
import Button from "../ContentfulTemplateButton"
import Close from "../../images/times-solid.svg"
import Spinner from 'react-bootstrap/Spinner'
import axios from 'axios'

class WebExMeetings extends Component {
  constructor(props) {
    super(props)
    this.props = props
    this.state = {
      isModalOpen: false,
      isFormSubmitted : false,
      isShowSpinner : false
    };
    this.message={
      head:'Thank You',
      body:'We have received your enquiry and we will get back to you soon.',
    }
    this.SignupSchema = Yup.object().shape({
      email: Yup.string()
        .email('Invalid email')
        .required('Required'),
      phone: Yup.string()
        .matches(/[^a-bA-Z]/g, "Phone number is not valid")
    });
  }
  handleModalOpen = event => {
    this.setState({ isModalOpen: true })
  }

  handleModalClose = event => {
    this.setState({ isModalOpen: false })
    setTimeout(() => {
      this.setState({ isFormSubmitted: false })
    }, 500);
    
  }
  render() {

    return (
      <>
        <button className="btn" onClick={this.handleModalOpen}>{this.props.title}</button>
        <Modal show={this.state.isModalOpen} onHide={this.handleModalClose} backdrop="static" centered dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title" >
          <Modal.Header>
            {!this.state.isFormSubmitted ? (
              <h2 className="form-titile">{this.props.title}</h2>
              ) : (
                <h2 className="form-titile">{this.message.head}</h2>
            )}
            <button onClick={this.handleModalClose}><img src={Close} alt={`close`} width={`20px`} height={`20px`} /></button>
          </Modal.Header>
          <Modal.Body>
          {!this.state.isFormSubmitted ? (
            <Formik
                  initialValues={{
                    email: '',
                    phone: ''
                  }}
                  validationSchema={this.SignupSchema}
                  onSubmit={(values, { resetForm }) => {
                    values.form_type = "webex90daytrial"
                    values.firstname = "90 Day Free Trial"
                    let data = {
                      body: values, // replace this with attributes you need
                      headers: {}, // OPTIONAL
                    };
                    this.setState({ isShowSpinner: true })
                    axios.post("https://formspree.io/f/mbldaqdz",data)
                      .then(response => {
                        resetForm();
                        this.setState({ isFormSubmitted: true })
                        this.setState({ isShowSpinner: false })
                      })
                      .catch(error => {
                        resetForm();
                        this.message={
                          head:'Sorry',
                          body:'Unfortunately an error occurred. Please try again later',
                        }
                        this.setState({ isFormSubmitted: true })
                        this.setState({ isShowSpinner: false })
                      });
                    
                  }}
                >
              {({ errors, touched }) => (
                <Form>
                  <div>
                    <Field placeholder="Email (Required)" name="email" type="email" />
                    {errors.email && touched.email ? <div className={`text-danger`}>{errors.email}</div> : null}
                  </div>
                  <div>
                    <Field placeholder="Telephone" name="phone" type="tel" />
                    {errors.phone && touched.phone ? <div className={`text-danger`}>{errors.phone}</div> : null}
                  </div>
                  <span className="text-center d-block">
                    {this.state.isShowSpinner ? (
                      <Spinner animation="grow" />
                      ) : (
                        <Button title="Send" targetType="Submit" />
                      )}
                  </span>
                </Form>

              )}
            </Formik>
            ) : (
              <p>
                {this.message.body}
                <button className="btn text-center" onClick={this.handleModalClose}>Close</button>
              </p>
          )}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default WebExMeetings