import React from "react";
import {position} from "../../helper"
import "./ContentfulTemplateTile.scss"
import { Link } from "gatsby";
import LightboxImage from './LightboxImage';
import V_handshake from '../images/V_handshake.svg';

const ContentfulTemplateTile = ({heading, text, image: {file: {url}, title}, imageClasses, textPosition, __typename, headingSize}) => {
    let classes =(imageClasses ? imageClasses.join(" ").toLowerCase(): "");
    return (
        <div className={`text-${position(textPosition, "center")} ${__typename}`}>
            <span className={`${classes}`}>
                {/* <img src={url} alt={title}/> */}
                <LightboxImage imgSrc={url} imgAlt={title} className="" width=""/>
            </span>
            <HeadingSize heading={heading} headingSize={headingSize} />
            <p>{text}</p>
            
        </div>
    )
}

const HeadingSize = ({heading, headingSize}) => { 
    if (headingSize) {
        let hs = headingSize.toLowerCase();
        const CustomTag = `${hs}`;
        return  <CustomTag>{heading}</CustomTag>
    }
    return  <h3>{heading}</h3>
}

const Tile = (props) => {
    if(props.link) {
        return (
            <Link to={`/${props.link}`}><ContentfulTemplateTile {...props}/></Link>
        )
    }
    return (<ContentfulTemplateTile {...props}/>)
}

export default Tile