
import React ,{Component}from "react"
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

class LightboxImage extends Component {
    constructor(props) {
        super(props);
        this.props=props;
        this.state = {
            isOpen: false,
          };
          this.imgData='';
    }
    openLightBox(data){
        this.imgData=data
        this.setState({ isOpen: true })
    }


    render() {
        const { isOpen } = this.state;
        return (
            <>
                <img src={this.props.imgSrc} alt={this.props.imgAlt} className={this.props.className} width={this.props.width} onClick={() => this.openLightBox(this.props.imgSrc)} />
                {isOpen && (
                    <Lightbox mainSrc={this.imgData} onCloseRequest={() => this.setState({ isOpen: false })} /> 
                )} 
            </>
        );
        
    }
}

export default LightboxImage


