import React from "react";
import * as Components from "./AllComponents"
import { templateWidth } from "../../helper"
import { Col } from "react-bootstrap"
import { position } from "../../helper"
const ContentfulTemplateColumn = ({ components, desktopGridType, tabletGridType, mobileGridType, textPosition, cssClasses }) => {
    return (
        <Col
            className={`text-${position(textPosition, "center")} ${cssClasses}`}
            md={templateWidth(desktopGridType)}
            sm={templateWidth(tabletGridType)}
            xs={templateWidth(mobileGridType)}>
            {components.map((component, i) => {
                let Component = Components[component.__typename !== "ContentfulPage" ? component.__typename : "ContentfulTemplateTileAlt"]
                return (
                    <Component key={i} {...component} textPosition={textPosition} />
                )
            })}
        </Col>
    )
}


export default ContentfulTemplateColumn