import { ifError } from "assert";
import React from "react";
import * as Forms from "./forms/AllForms"
const ContentfulTemplateForm = ({formType, title, url}) => {
    let Form = Forms[formType.replace(/[&\s]/g, "")]
    return (
            <Form title={title} url={url}/>
    )
}

export default ContentfulTemplateForm