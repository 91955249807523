import React from "react";
import Column from "./ContentfulTemplateColumn"
import { Row } from "react-bootstrap";
import {formatForId} from "../../helper";
import "./ContentfulTemplateSection.scss"
const ContentfulTemplateSection = ({heading, cssClass, background, columns}) => {
    return (
        <section className={cssClass} style={background !== null ? {  backgroundImage: `url(${background.file.url})`,backgroundRepeat: `no-repeat`,backgroundSize: `cover` } : {backgroundImage : `none`}} id={formatForId(heading)} >
            <Row className="content">
                {
                    columns.map((column, i) => {
                        return (<Column key={i} {...column} />)
                    })
                }
            </Row>
        </section>
    )
}

export default ContentfulTemplateSection