import React, {useState} from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import "./Form.scss"
import Button from "../ContentfulTemplateButton"
import Modal from 'react-bootstrap/Modal'
import Close from "../../images/times-solid.svg"
import Spinner from 'react-bootstrap/Spinner'
import axios from 'axios'

var message={
  head:'Thank You',
  body:'We have received your enquiry and we will get back to you soon.',
}
const ContactUs = ({title}) => {
  const [isModalOpen, setModal] = useState(false);
  const [isSpinner, setSpinner] = useState(false);
  
  const SignupSchema = Yup.object().shape({
    fullName: Yup.string()
      .max(100, 'Too Long!'),
    email: Yup.string()
      .email('Invalid email')
      .required('Required'),
    phone: Yup.string()
      .matches(/[^a-bA-Z]/g, "Phone number is not valid")
  });
  const handleModalClose = event => {
    setModal(!isModalOpen)
  }

  return (
    <div>
      <h2>{title}</h2>
      <div className="separator"></div>
      <Formik
        initialValues={{
          fullName: '',
          email: '',
          phone: '',
          message: ''
        }}
        validationSchema={SignupSchema}
        onSubmit={(values, { resetForm }) => {
          let names = values.fullName.split(" ")
          if(names.length) {
            if(names.length > 1) {
              values.lastname = names[names.length -1]
              names.pop()
              values.firstname = names.join(" ")
            }
            else {
              values.firstname = values.fullName
              values.lastname = ""
            }
          }
          else {
            values.firstname = "Anonymous";
          }
          values.form_type = "contact"
          let data = {
            body: values,
            headers: {},
          };
          setSpinner(true)
          axios.post("https://formspree.io/f/mbldaqdz",data)
            .then(response => {
              setModal(!isModalOpen)
              setSpinner(false);
              resetForm();
            })
            .catch(error => {
              message={
                head:'Sorry',
                body:'Unfortunately an error occurred. Please try again later',
              };
              setSpinner(false);
              setModal(!isModalOpen)
              resetForm();
            });
          
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <div>
              <Field placeholder="Name" name="firstName" />
            </div>
            <div>
              <Field placeholder="Email (Required)" name="email" type="email" />
              {errors.email && touched.email ? <div className={`text-danger`}>{errors.email}</div> : null}
            </div>
            <div>
              <Field placeholder="Telephone" name="phone" type="tel" />
              {errors.phone && touched.phone ? <div className={`text-danger`}>{errors.phone}</div> : null}
            </div>
            <div>
              <Field placeholder="Your Message" name="message" as="textarea" rows="5"/>
            </div>
            {isSpinner ? (
                <Spinner animation="grow" />
            ) : (
                <Button title="Send" targetType="Submit" />
            )}

          </Form>
        )}
      </Formik>
       <Modal show={isModalOpen} onHide={handleModalClose}  backdrop="static" centered dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title" >
          <Modal.Header>
              <h2 className="form-titile">{message.head}</h2>
              <button onClick={handleModalClose}><img src={Close} alt={`close`} width={`20px`} height={`20px`} /></button>
          </Modal.Header>
          <Modal.Body>
            {message.body}
            <button className="btn text-center" onClick={handleModalClose}>Close</button>
          </Modal.Body>
        </Modal>
    </div>)
}

export default ContactUs