import React from "react";
import { Link } from "gatsby"
import scrollTo from 'gatsby-plugin-smoothscroll';
import "./ContentfulTemplateButton.scss"

const ContentfulTemplateButton = ({title, targetContent, targetType}) => {
    if(targetType === "Internal Link"){
        return (
            <Link className="btn" to={'/'+targetContent}>{title}</Link>
        )
    }else if(targetType === "Scroll To Section"){
        return (
            <button className="btn" onClick={() => scrollTo('#'+targetContent)}>{title}</button>
        )
    }else if(targetType === "External Link"){
        return (
            <a href={targetContent} className="btn" rel="noreferrer"  target="_blank" >{title}</a>
        )
    }
    else if(targetType === "Submit") {
        return (
            <button className="btn" type="submit">{title}</button>
        )
    }
    return <></>
}

export default ContentfulTemplateButton