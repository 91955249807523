import React, { Component } from "react"
import {Link} from 'gatsby'
import Head from "./Head"
import Nav from "./Nav"
import Footer from "./Footer"
import { Container } from "react-bootstrap"
import "./Layout.scss"
class Layout extends Component {
    constructor(props) {
        super(props);
        this.props = props
        this.state = {
            showContactBanner: false,
            showCookieBanner: false
        }
    }
    handleContactBanner = () => {
        if (typeof window !== undefined && typeof document !== undefined) {
            let footer = document.querySelector("footer")
            if(footer) {
                let footerPosition = footer.offsetTop - (window.outerHeight - window.innerHeight) - 100 - footer.offsetHeight
                if (window.pageYOffset && window.pageYOffset < footerPosition) {
                    this.setState({ showContactBanner: true })
                }
                else {
                    this.setState({ showContactBanner: false })
                }
            }
        }
    }
    setCookie = () => {
        if (typeof window !== undefined && typeof document !== undefined) {
            document.cookie = "accepted_privacy_policy=1"
            this.setState({ showCookieBanner: false });
        }
        
    }
    getCookie(cname) {
        if (typeof window !== undefined && typeof document !== undefined) {
            var name = cname + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');
            for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
            }
            return "";
        }
      }
    componentDidMount() {
        if (typeof window !== undefined && typeof document !== undefined) {
            this.handleContactBanner()
            window.onscroll = this.handleContactBanner
            if(parseInt(this.getCookie("accepted_privacy_policy")) === 1){
                this.setState({ showCookieBanner: false });    
            }
            else {
                this.setState({ showCookieBanner: true });
            }
        }
    }

    render() {
        let { title, description, siteMetadata, children } = this.props;
        return (
            <>
                <Head {...{ title, description }} />
                <main>
                    <Container fluid>
                        <Nav />
                        {children}
                    </Container>
                </main>
                <Footer />
                <div className="contact-banner" style={{ height: this.state.showContactBanner ? "65px" : "0" }}>
                    <div>
                        <a href={`tel:${siteMetadata.phone['en_GB'].phone_number}`}>{siteMetadata.phone['en_GB'].title}</a>
                        <a href={`tel:${siteMetadata.phone['en_US'].phone_number}`}>{siteMetadata.phone['en_US'].title}</a>   
                        <a href={`mailto:${siteMetadata.email}`}>{siteMetadata.email}</a>
                    </div>  
                </div>
                {this.state.showCookieBanner ? (
                        <div className="privacy-notice">
                        <div>
                            <p>
                                This website uses cookies to improve your experience. Please view our <Link to={'/privacy-notice'}>Privacy Notice</Link> for more information
                            </p>
                            <button className="btn" onClick={this.setCookie} type="button">Accept</button>
                        </div>
                    </div>
        
                ) : (
                    
                    <></>
                )}
 

                
            </>
        )
    }
}

export default Layout